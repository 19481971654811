import React, { useEffect, useState } from "react";
import { useQuery } from '@apollo/client';
import SEO from '../components/seo/seo';
import Loading from '../components/loading/loading';
import ExpStyle from '../components/styled/exerience-style';
import Video from '../components/video/videos';
import GET_EXPERIENCE from '../graphql/get-experience';

export default function Page() {
  const [ experiencePage, setExperiencePage ] = useState();
  const [ seo, setSEO ] = useState();
  const { loading, data: pageData } = useQuery(GET_EXPERIENCE, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (!loading) {
      setExperiencePage(pageData.pages.nodes[0]);
      setSEO(pageData.pages.edges[0].node.seo);
    }
  }, [loading, pageData, experiencePage]);

  const heroImage = experiencePage?.experienceacf.backgroundImage?.sourceUrl;

  return (
    <>
      {loading ? (
        <>
          <Loading />
        </>
      ) :(
        <>
          <SEO
            title={seo?.title || 'The Kinloch Experience'}
            description={seo?.description}
          />
          <ExpStyle>
            <div
              style={{ backgroundImage: `url(${heroImage})`}}
              className="background"
            >
              <div className="blue-overlay">
                <div className="container">
                  <div className="row">
                    <div className="col-12 page-title">
                      <h1>{experiencePage?.experienceacf?.pageTitle}</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <section className="subhead">
              <div className="container">
                <div className="row">
                  <div className="col text-white spacing" dangerouslySetInnerHTML={{ __html: experiencePage?.experienceacf?.subheadInfo }} />
                </div>
              </div>
            </section>
            
            <section className="history">
              <div className="container">
                <div className="row">
                  <div className="col">
                    <h2>{experiencePage?.experienceacf?.historyTitle}</h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col" dangerouslySetInnerHTML={{ __html: experiencePage?.experienceacf?.ourHistory }} />
                </div>
              </div>
            </section>

            <section className="video">
              <div className="container">
                <div className="row">
                  <div className="col">
                    <h2>{experiencePage?.experienceacf?.videoTitle}</h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 offset-lg-2 col-lg-8">
                    <Video
                      videoSrcURL={ experiencePage?.experienceacf?.videov2 }  // attach either videoSrcURL={ import-here }, or by URL
                    />   
                  </div>
                </div>
              </div>
            </section>

          </ExpStyle>
        </>
      )}
    </>
  )
}
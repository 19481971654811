import { gql } from "@apollo/client";

export const GET_EXPERIENCE = gql`
  query GetExperience {
    pages(where: {title: "The Kinloch Experience"}) {
      nodes {
        id
        slug
        status
        date
        template {
          templateName
        }
        title
        content
        experienceacf {
          pageTitle
          historyTitle
          ourHistory
          subheadInfo
          video
          videoTitle
          videov2
          backgroundImage {
            sourceUrl
            srcSet
          }
        }
      },
      edges {
        node {
          seo {
            title
            focuskw
            metaDesc
          }
        }
      }
    }
  }
`;

export default GET_EXPERIENCE;
import styled from 'styled-components'

const ExpStyle = styled.div`

  .background {
    background-color: #12284C;
    background-color: var(--darkblue);
    height: 465px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .blue-overlay {
    display: -webkit-flex; /* Safari */
    display:flex;
    background-color: rgb(18,40,76, .46);
    height: 100%;
    align-items: center;

    .page-title {
      width: 100%;
      text-align: center;
      
      h1 {
        font-family: 'Copperplate', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-family: var(--Copperplate);
        color: white;
      }

    } 

  }

  .subhead {
    background-color: #12284C;
    background-color: var(--darkblue);
    font-family: 'OpenSansRegular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-family: var(--OpenSansRegular);
    font-size: 1.375rem;
    line-height: 2.375rem;

    .spacing {
      margin: 0 0 5rem;
    }
    
  }

  .history {
    font-family: 'OpenSansLight', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-family: var(--OpenSansLight);
    line-height: 2rem;
    font-size: 1.063rem;
    
    h2 {
      font-family: 'CopperplateBold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-family: var(--CopperplateBold);
      font-size: 1rem;
      color: #12284C;
      color: var(--darkblue);
      font-weight: bold;
      padding: 1.5rem 0;
    }

  }

  .video {
    font-family: 'OpenSansLight', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-family: var(--OpenSansLight);
    line-height: 2rem;
    font-size: 1.063rem;
    
    h2{
      font-family: 'CopperplateBold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-family: var(--CopperplateBold);
      font-size: 1rem;
      color: #12284C;
      color: var(--darkblue);
      font-weight: bold;
      padding: 1.5rem 0;
    }

  }

`;

export default ExpStyle;